/* global contactModule:true */

angular.module('com.aa.contact').service('AttachmentsDialogService', function ($timeout) {

    var noAttachmentsDialog = {
        showNoAttachmentsDialog: function (name) {
            return name === "SVAN" ? new showSVANDialog(name) : new showDialog(name);
        },

	    showFilesStillUploadingDialog: function () {
	    	return new showUploadingDialog();
	    }
    };

    var showSVANDialog = function (name) {
        var doesAttachmentExist = true;
        var dialogName = 'noAttachments' + name;
        if (doesAttachmentExist) {
            contactModule.contactAA.util.aaDialog(dialogName, {
                buttons: [
                    {
                        name: contactModule.goBackModal,
                        cssClass: "btn",
                        callback: function () {
                        },
                    }],
                onClose: function () {
                    $j('#btnSubmit').focus();
                },
                width: 'small',
                autoOpen: false,
                closeDialog: true,
                toggleScroll: true,
                closeOnEscape: false
            });
            contactModule.contactAA.util.aaDialog(dialogName).openDialog();
            if ($device.mobile) {
                $j('#noAttachmentsDialogClose').hide();
            }
        }
    };

    var showDialog = function (name) {
    	var doesAttachmentExist = true;
    	var dialogName = 'noAttachments' + name;
    	if (doesAttachmentExist) {
                contactModule.contactAA.util.aaDialog(dialogName, {
                buttons: [
                    {
                        name: contactModule.submitModal,
                        cssClass: "btn",
                        callback: function () {
                        	$j('#attachmentDialogPopupFlag').val('false');
                        	$j('#currentForm').submit();
                        }
                    },
                    {
                        name: contactModule.goBackModal,
                        cssClass: "btn btn-secondary",
                        callback: function () {
                        },
                    }],
                onClose: function () {
                },
                width: 'small',
                autoOpen: false,
                closeDialog: true,
                toggleScroll: true,
                closeOnEscape: false
            });
            contactModule.contactAA.util.aaDialog(dialogName).openDialog();
            if ($device.mobile) {
                $j('#noAttachmentsDialogClose').hide();
            }
        }
    };

    var showUploadingDialog = function () {
    	var doesAttachmentExist = true;
    	var dialogName = 'filesStillUploading';
    	if (doesAttachmentExist) {
                contactModule.contactAA.util.aaDialog(dialogName, {
                buttons: [
                    {
                        name: contactModule.ok,
                        cssClass: "btn",
                        callback: function () {
                        }
                    }],
                onClose: function () {
                },
                width: 'small',
                autoOpen: false,
                closeDialog: true,
                toggleScroll: true,
                closeOnEscape: false
            });
            contactModule.contactAA.util.aaDialog(dialogName).openDialog();
            if ($device.mobile) {
                $j('#filesStillUploadingDialogClose').hide();
            }
        }
    };

    var doSomethingHere = function () {
        $timeout(function () {
            var fallBackTopic = $j('input[name="fallBackTopic"]').val();
        }, 0);
    };

    return noAttachmentsDialog;
});
